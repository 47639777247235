import React, { Fragment } from "react";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import Img from "gatsby-image";
import Started from "../components/Layout/started";
import goArrow from "../assets/icon-arrow.svg";
import { StaticQuery, Link, graphql } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import pageStyles from "./page.module.less";
import styles from "./news.module.less";
import SEO from "../components/SEO";
import ScrollAnimation from "react-animate-on-scroll";

const BLOG_POST_QUERY = graphql`
  query BlogPostQuery {
    blogDefault: file(relativePath: { eq: "Blog/blog_default_fullhd.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog-post/" } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          html
          excerpt(pruneLength: 220)
        }
      }
    }
  }
`;

const GoArrow = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 20px;
  img {
    width: 20px;
    transition: all 0.5s;
  }
`;

const TopBlogDiv = styled.div`
  margin-top: -160px;
  background-color: white;
  position: relative;
  margin-bottom: 70px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    ${GoArrow} {
      img {
        transform: translateX(2px);
        width: 20px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    margin-top: -60px;
  }
  @media screen and (max-width: 768px) {
    margin-top: -80px;
    margin-bottom: 40px;
  }
`;

const StyledMainBlogPostHeader = styled.h1`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 30px;
  @media screen and (max-width: 991px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

const StyledMainBlogText = styled.p`
  font-size: 16px;
  letter-spacing: 0.02px;
  color: #242d41;
  line-height: 26px;
  font-weight: 300;
`;

const LowerBlogText = styled.h4`
  color: #242d41;
  font-size: 16px;
  padding: 30px;
  line-height: 1.7;
  font-weight: 600;
  @media screen and (max-width: 1200px) {
    padding: 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 30px;
  }
  @media screen and (max-width: 576px) {
    padding: 20px;
  }
`;

const TopTextContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StandardBlogPostContent = styled.div`
  position: relative;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  background: #fff;
  height: 100%;
  transition: all 0.2s;
  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  }
`;

const GradientSection = styled.div`
  height: 45px;
  width: 100%;
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  @media screen and (max-width: 768px) {
    margin-top: -65px;
  }
`;

export default (props) => {
  const { blogDefault } = props.data;
  return (
    <Layout>
      <SEO title='News' />
      <PageHeader mediumHeader />
      <StaticQuery
        query={BLOG_POST_QUERY}
        render={({ allMarkdownRemark }) => (
          <Container>
            <Row className='justify-content-md-center'>
              <Col lg={10} md={11}>
                <Row>
                  {allMarkdownRemark.edges.map((edge, index) => {
                    if (index === 0) {
                      return (
                        <Fragment>
                          <Col md={12}>
                            <ScrollAnimation
                              animateOnce
                              animateIn='fadeIn'
                              offset={0}
                            >
                              <TopBlogDiv>
                                <Row>
                                  <Link
                                    to={edge.node.frontmatter.slug}
                                    className={pageStyles.fullLink}
                                  />
                                  <Col md={6} className={pageStyles.coliPad}>
                                    <Img
                                      fluid={
                                        edge.node.frontmatter.featuredImage
                                          ? edge.node.frontmatter.featuredImage
                                              .childImageSharp.fluid
                                          : blogDefault.childImageSharp.fluid
                                      }
                                      className={styles.topBlogImg}
                                    />
                                  </Col>
                                  <Col
                                    md={6}
                                    className={`${styles.topTextCol} ${pageStyles.coliPad}`}
                                  >
                                    <TopTextContent>
                                      <StyledMainBlogPostHeader>
                                        {edge.node.frontmatter.title}
                                      </StyledMainBlogPostHeader>
                                      <StyledMainBlogText>
                                        {edge.node.excerpt}
                                      </StyledMainBlogText>
                                    </TopTextContent>
                                    <GoArrow>
                                      <img src={goArrow} alt='goArrow' />
                                    </GoArrow>
                                  </Col>
                                </Row>
                              </TopBlogDiv>
                            </ScrollAnimation>
                          </Col>
                        </Fragment>
                      );
                    } else {
                      return (
                        <Col
                          md={4}
                          className={`${styles.standardBlog} ${pageStyles.coliPadHalf}`}
                        >
                          <ScrollAnimation
                            animateOnce
                            animateIn='fadeIn'
                            offset={0}
                            delay={100 * index}
                          >
                            <StandardBlogPostContent>
                              <Link
                                to={edge.node.frontmatter.slug}
                                className={pageStyles.fullLink}
                              />
                              <Img
                                fluid={
                                  edge.node.frontmatter.featuredImage
                                    ? edge.node.frontmatter.featuredImage
                                        .childImageSharp.fluid
                                    : blogDefault.childImageSharp.fluid
                                }
                                className={styles.standardBlogImage}
                              />
                              <LowerBlogText>
                                {edge.node.frontmatter.title}
                              </LowerBlogText>
                            </StandardBlogPostContent>
                          </ScrollAnimation>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        )}
      />
      <GradientSection />
      <Container>
        <Started />
      </Container>
    </Layout>
  );
};
